import React from "react";
import styled from "styled-components";
import { Button, Link } from "@material-ui/core";

import { _ } from "js/vendor";
import getLogger, { LogGroup } from "js/core/logger";
import db from "js/db";
import { getStaticUrl, isDevelopment, serverUrl } from "js/config";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import { trackActivity } from "js/core/utilities/utilities";
import getSlidePlaybackStagesCount from "common/utils/getSlidePlaybackStagesCount";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import AuthenticatedUserTaskpane from "js/react/views/PPTAddin/Components/AuthenticatedUserTaskpane";
import { auth } from "js/firebase/auth";

import { getBaiDataFromPPTSlide, getCurrentPPTSlideData, parsePPTTheme } from "./officeHelpers";
// To avoid circular dependency, we need to import the styled components from the SharedStyles file
import { LinkWrapper, LogoContainer, LogoImage } from "./officeHelpers/SharedStyles";

const logger = getLogger(LogGroup.PPT_ADDIN);

const Container = styled.div`
    height: 100vh;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px;
    color: #ffffff;

    user-select: none;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
`;

const Message = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #A9A9A9;
`;

const BaiDescriptionContainer = styled.div`    
    width: 100%;
    margin-top: 40px;
    margin-bottom: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    text-align: center;
`;

const AlreadyHaveAnAccount = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;

    > p {
        margin: 0;
        font-weight: bold;
        font-size: 14px;
    }
`;

const RegisteredContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
`;

const IframeBaiVideo = styled.iframe`
    @media (max-height: 600px) {        
        display: none;
    }

    width: 100%;
    margin-top: 30px;
    cursor: pointer;
    border: 0;
`;

const BaiDescriptionHeader = styled.div`
    font-weight: 600;
    font-size: 21px;
    letter-spacing: 1.5px;
    color: #ffffff;
    margin-top: 30px;
`;

const BaiDescriptionText = styled.div`
    @media (max-height: 380px) {        
        display: none;
    }

    font-size: 12px;
    letter-spacing: 1px;
    color: #c4c4c4;
    margin-top: 10px;
`;

const SignInButton = styled(Button)`
    &&& {
        margin-top: 30px;
        font-weight: 400;
    }
`;

class PPTAddinTaskpane extends React.Component {
    static defaultState = {
        fetching: false,
        isShowingEditor: false,
        isShowingAuthenticateDialog: false,
        currentSlideModel: null,
        currentThemeModel: null,
        currentSlidePlaybackStage: null,
        presentationThemeModel: null,
        currentPPTSlideId: null,
        currentPPTSlideIndex: null,
        currentTeamSlideHasUpdates: false,
        currentTeamSlideId: null,
        error: null,
        orgId: null,
        currentPlan: null
    }

    constructor() {
        super();

        this.state = { ...PPTAddinTaskpane.defaultState };

        this.loadCurrentSlidePromiseChain = Promise.resolve();
        this.pptThemeModel = null;
    }

    get isSignedIn() {
        const { firebaseUser } = this.props;
        return !!firebaseUser?.emailVerified;
    }

    componentDidMount() {
        Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, this.loadCurrentSlide);

        if (this.isSignedIn) {
            this.loadCurrentSlide();
        }
    }

    componentWillUnmount() {
        Office.context.document.removeHandlerAsync(Office.EventType.DocumentSelectionChanged, this.loadCurrentSlide);
    }

    componentDidUpdate(prevProps, prevState) {
        const { firebaseUser } = this.props;
        const { isShowingAuthenticateDialog } = this.state;

        if (prevProps.firebaseUser !== firebaseUser) {
            this.loadCurrentSlide();
            return;
        }

        if (prevState.isShowingAuthenticateDialog && !isShowingAuthenticateDialog && firebaseUser) {
            if (!firebaseUser.emailVerified) {
                firebaseUser.reload()
                    .then(() => firebaseUser.getIdToken(true))
                    .then(() => {
                        this.loadCurrentSlide();
                    });
            } else {
                this.loadCurrentSlide();
            }
        }
    }

    handleOpenBaiVideo = () => {
        Office.context.ui.displayDialogAsync(
            `${window.location.origin}/ppt-addin/video`,
            {
                width: 70,
                height: 71
            }
        );
    }

    loadCurrentSlide = async (forceReload = false) => {
        const { fetching: wasFetching } = this.state;

        if (!this.isSignedIn) {
            return;
        }

        this.loadCurrentSlidePromiseChain = this.loadCurrentSlidePromiseChain
            .then(async () => {
                try {
                    const { currentPPTSlideId, currentPPTSlideIndex } = await getCurrentPPTSlideData();
                    if (!forceReload && currentPPTSlideId === this.state.currentPPTSlideId && currentPPTSlideIndex === this.state.currentPPTSlideIndex && !this.state.error) {
                        // Already loaded the same slide
                        return;
                    }

                    if (!wasFetching) {
                        this.setState({ fetching: true });
                    }

                    const { currentSlideModel, currentSlidePlaybackStage, currentThemeModel, presentationThemeModel } = await getBaiDataFromPPTSlide(currentPPTSlideIndex);

                    let currentTeamSlideId = null;
                    let currentTeamSlideHasUpdates = false;
                    let orgId = null;
                    if (currentSlideModel?.libraryItemId) {
                        try {
                            const [libraryItemContentModifiedAt, libraryItemContentId, teamId] = await Promise.all([
                                db("libraryItems").child(currentSlideModel.libraryItemId).child("contentModifiedAt").once("value").then(snap => snap.val()),
                                db("libraryItems").child(currentSlideModel.libraryItemId).child("contentId").once("value").then(snap => snap.val()),
                                db("libraryItems").child(currentSlideModel.libraryItemId).child("teamId").once("value").then(snap => snap.val())
                            ]);

                            // We want to get the orgId of the team, so we select the relevant workspace for this team slide
                            orgId = await db("teams").child(teamId).child("orgId").once("value").then(snap => snap.val());

                            if (libraryItemContentModifiedAt && libraryItemContentModifiedAt > currentSlideModel.modifiedAt) {
                                currentTeamSlideHasUpdates = true;
                                currentTeamSlideId = libraryItemContentId;
                            }
                        } catch (err) {
                            logger.error(err, `[PPTAddinTaskpane] failed to load team slide data`, { currentPPTSlideId });
                        }
                    }

                    this.setState({
                        currentPPTSlideId,
                        currentPPTSlideIndex,
                        currentSlideModel,
                        currentSlidePlaybackStage,
                        currentThemeModel,
                        presentationThemeModel,
                        currentTeamSlideHasUpdates,
                        currentTeamSlideId,
                        orgId,
                        error: null
                    });
                } catch (err) {
                    let error;
                    if (err.code !== 5001) {
                        error = err;
                        logger.error(err, "[PPTAddinTaskpane] loadCurrentSlide() failed", { errCode: err.code });
                    }

                    this.setState({
                        currentPPTSlideId: null,
                        currentPPTSlideIndex: null,
                        currentSlideModel: null,
                        currentSlidePlaybackStage: null,
                        currentThemeModel: null,
                        presentationThemeModel: null,
                        currentTeamSlideHasUpdates: false,
                        currentTeamSlideId: null,
                        orgId: null,
                        error
                    });
                } finally {
                    if (!wasFetching) {
                        this.setState({ fetching: false });
                    }
                }
            });

        await this.loadCurrentSlidePromiseChain;
    }

    async insertSlides(insertAtIndex, { pptSlideData, slideModel = null, themeModel }) {
        const { presentationThemeModel } = this.state;

        await PowerPoint.run(async context => {
            const presentation = context.presentation;

            const insertOptions = {};
            if (insertAtIndex > 0) {
                const targetSlide = presentation.slides.getItemAt(insertAtIndex - 1);
                targetSlide.load("id");
                await context.sync();
                insertOptions.targetSlideId = `${targetSlide.id}#`;
            }
            presentation.insertSlidesFromBase64(pptSlideData, insertOptions);
            // If we're online, it will take more time to insert the slide
            // So we artificially wait for 1 second to make sure the slide is inserted
            await this.wait(1);
            await context.sync();

            // Save presentation theme (if not exists or was edited)
            if (!_.isEqual(presentationThemeModel, themeModel)) {
                presentation.tags.add("BAI_THEME_MODEL", JSON.stringify(themeModel));
                await context.sync();
            }

            if (!slideModel) {
                // Nothing else to save, exiting
                return;
            }

            // Save slide and theme models into it
            const playbackStagesCount = getSlidePlaybackStagesCount(slideModel);
            for (const stageIdx of [...new Array(playbackStagesCount).keys()]) {
                const slide = presentation.slides.getItemAt(insertAtIndex + stageIdx);
                slide.tags.add("BAI_SLIDE_MODEL", JSON.stringify(slideModel));
                slide.tags.add("BAI_THEME_MODEL", JSON.stringify(themeModel));
                slide.tags.add("BAI_SLIDE_PLAYBACK_STAGE", `${stageIdx}`);
                await context.sync();
            }
        });
    }

    wait(timeInSeconds) {
        return new Promise(resolve => setTimeout(resolve, timeInSeconds * 1000));
    }

    async removeCurrentSlides() {
        const { currentSlideModel, currentSlidePlaybackStage, currentPPTSlideIndex } = this.state;

        let firstRemovedSlideIndex = currentPPTSlideIndex;
        await PowerPoint.run(async context => {
            const presentation = context.presentation;

            const currentSlidePlaybackStagesCount = getSlidePlaybackStagesCount(currentSlideModel);
            const startIndex = currentPPTSlideIndex - currentSlidePlaybackStage;
            const indexesToRemove = [...new Array(currentSlidePlaybackStagesCount).keys()].map(idx => idx + startIndex).reverse();
            for (const slideIndex of indexesToRemove) {
                const slide = presentation.slides.getItemAt(slideIndex);
                const slideModelTag = slide.tags.getItemOrNullObject("BAI_SLIDE_MODEL");
                slideModelTag.load("value");
                await context.sync();
                if (slideModelTag.value) {
                    const slideModel = JSON.parse(slideModelTag.value);
                    if (slideModel.id === currentSlideModel.id) {
                        slide.delete();
                        await context.sync();
                        firstRemovedSlideIndex = slideIndex;
                    }
                }
            }
        });

        return firstRemovedSlideIndex;
    }

    async saveSlide(saveAsNewSlide, {
        slideModel,
        themeModel,
        pptSlideData,
        workspaceId,
        templateId,
        templateTitle,
        isSavedAsImage
    }) {
        const { currentPPTSlideIndex, currentPPTSlideId } = this.state;

        this.setState({ fetching: true });
        try {
            let insertAtIndex;
            if (saveAsNewSlide) {
                insertAtIndex = currentPPTSlideIndex != null ? currentPPTSlideIndex + 1 : 0;
            } else {
                insertAtIndex = await this.removeCurrentSlides();
            }

            pptSlideData = pptSlideData.substr(pptSlideData.indexOf("base64,") + 7);
            await this.insertSlides(insertAtIndex, {
                pptSlideData,
                // Only image slides can be edited again, so we only save model for image slides
                slideModel: isSavedAsImage ? slideModel : null,
                themeModel
            });

            const analyticsProps = {
                workspace_id: workspaceId,
                template_id: templateId,
                template_title: templateTitle,
                format: isSavedAsImage ? "jpg" : "ppt"
            };
            trackActivity("PPTSlide", saveAsNewSlide ? "Saved" : "Edited", null, null, analyticsProps);
        } catch (err) {
            logger.error(err, "[PPTAddinTaskpane] saveSlide() failed", { currentPPTSlideId });

            this.setState({ error: err });
        } finally {
            this.setState({ fetching: false });
        }

        // Force load current slide data (including bai theme) in case ppt didn't switch to the new slide
        this.loadCurrentSlide(true);
    }

    openAuthenticateDialog = async (event, page = "signIn") => {
        const { firebaseUser } = this.props;

        // If we're logged in, we need to log out first
        if (page === "createAccount" && firebaseUser) {
            await auth().signOut();
        }

        this.setState({ isShowingAuthenticateDialog: true });

        Office.context.ui.displayDialogAsync(
            `${window.location.origin}/ppt-addin/authenticate/${page}`,
            {
                width: 70,
                height: 70
            },
            ({ value: dialog }) => {
                this.authenticateDialog = dialog;
                dialog.addEventHandler(Office.EventType.DialogMessageReceived, eventArgs => {
                    const message = JSON.parse(eventArgs.message);
                    if (message.eventType === "close") {
                        dialog.close();
                        this.setState({ isShowingAuthenticateDialog: false });
                    }
                });
                dialog.addEventHandler(Office.EventType.DialogEventReceived, () => {
                    // Dialog force closed
                    this.setState({ isShowingAuthenticateDialog: false });
                });
            }
        );
    }

    async openEditorDialog(createNewSlide, workspaceId, legacy) {
        const {
            currentSlideModel,
            currentSlidePlaybackStage,
            currentThemeModel,
            currentTeamSlideId,
            presentationThemeModel
        } = this.state;

        this.setState({ isShowingEditor: true });

        if (createNewSlide && !presentationThemeModel && !this.pptThemeModel) {
            // Will load only once
            this.pptThemeModel = await parsePPTTheme().catch(err => logger.error(err, "[PPTAddinTaskpane] parsePPTTheme() failed"));
        }

        Office.context.ui.displayDialogAsync(
            legacy ? `${window.location.origin}/ppt-addin/legacy-editor` : `${window.location.origin}/ppt-addin/editor`,
            {
                width: 70,
                height: 70
            },
            ({ value: dialog }) => {
                dialog.addEventHandler(Office.EventType.DialogEventReceived, () => {
                    // Dialog force closed
                    this.setState({ isShowingEditor: false });
                });

                dialog.addEventHandler(Office.EventType.DialogMessageReceived, eventArgs => {
                    const message = JSON.parse(eventArgs.message);

                    if (message.eventType === "mounted") {
                        dialog.messageChild(JSON.stringify({
                            eventType: "initialize",
                            isCreatingNewSlide: createNewSlide,
                            slideModel: createNewSlide ? null : currentSlideModel,
                            slidePlaybackStage: createNewSlide ? null : currentSlidePlaybackStage,
                            themeModel: createNewSlide ? presentationThemeModel : currentThemeModel,
                            pptThemeModel: this.pptThemeModel,
                            teamSlideId: createNewSlide ? null : currentTeamSlideId,
                            workspaceId
                        }));
                        return;
                    }

                    if (message.eventType === "save-slide") {
                        this.saveSlide(createNewSlide, message)
                            .then(() => {
                                this.setState({ isShowingEditor: false });
                                dialog.close();
                            });
                        return;
                    }

                    if (message.eventType === "close") {
                        this.setState({ isShowingEditor: false });
                        dialog.close();
                        return;
                    }

                    if (message.eventType === "error") {
                        logger.error(message.error, "[PPTAddinTaskpane] openEditorDialog() error message received");
                        this.setState({ error: message.error, isShowingEditor: false });
                        dialog.close();
                        return;
                    }
                });
            }
        );
    }

    render() {
        const { firebaseUser } = this.props;
        const {
            fetching,
            error,
            currentSlideModel,
            currentTeamSlideHasUpdates,
            isShowingEditor,
            isShowingAuthenticateDialog,
            orgId
        } = this.state;

        const Content = (() => {
            if (error) {
                return (<>
                    <p>Sorry, we failed to load slide data, try again later</p>
                    {error.code && <p>Error code: {error.code}</p>}
                    {error.name && <p>Error name: {error.name}</p>}
                    {error.message && <p>Error message: {error.message}</p>}
                    {error.stack && <p>Error stack: {error.stack}</p>}

                    {/* Debug */}
                    {isDevelopment && <pre>{JSON.stringify(this.state, null, 2)}</pre>}
                </>);
            }

            if (isShowingEditor) {
                return <Message>editing slide...</Message>;
            }

            if (isShowingAuthenticateDialog) {
                return <Message>Authenticating...</Message>;
            }

            if (!this.isSignedIn) {
                return (<>
                    <BaiDescriptionContainer>
                        <IframeBaiVideo
                            allow="autoplay; fullscreen; picture-in-picture"
                            src="https://player.vimeo.com/video/915668463?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1&loop=1&muted=1&background=1&pip=0&controls=0"
                            title="The presentation software for everyone."
                        ></IframeBaiVideo>

                        <BaiDescriptionHeader>
                            Create beautiful presentations faster.
                        </BaiDescriptionHeader>
                        <BaiDescriptionText>
                            Beautiful.ai is an expert deck designer, so you don’t have to be. Our AI-powered slide templates apply the rules of great design in real-time. Just add content, and your slides adapt like magic.
                        </BaiDescriptionText>

                        <RegisteredContainer>
                            <SignInButton
                                variant="contained"
                                color="primary"
                                onClick={evt => this.openAuthenticateDialog(evt, "createAccount")}
                            >
                                GET STARTED
                            </SignInButton>

                            <AlreadyHaveAnAccount>
                                <p>Already have an account?</p>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={this.openAuthenticateDialog}
                                >
                                    Sign In
                                </Link>
                            </AlreadyHaveAnAccount>
                        </RegisteredContainer>
                    </BaiDescriptionContainer>

                    <LogoContainer>
                        <LinkWrapper href={`${serverUrl}/?`} target="_blank" >
                            <LogoImage src={getStaticUrl("/images/ppt-addin/logo.png")} />
                        </LinkWrapper>
                    </LogoContainer>
                </>);
            }

            if (this.isSignedIn) {
                return (<AuthenticatedUserTaskpane
                    orgId={orgId}
                    firebaseUser={firebaseUser}
                    openEditorDialog={(createNewSlide, workspaceId, legacy) => this.openEditorDialog(createNewSlide, workspaceId, legacy)}
                    currentSlideModel={currentSlideModel}
                    currentTeamSlideHasUpdates={currentTeamSlideHasUpdates}
                    onError={err => this.setState({ error: err })}
                />);
            }

            return null;
        })();

        return (
            <Container>
                <FetchingClickShield visible={fetching} backgroundColor="#000000" />
                {Content}
            </Container >
        );
    }
}

export default withFirebaseUser(PPTAddinTaskpane);
